<template>
  <section class="page-wrap">
    <!-- 表单搜索 start -->
    <section class="form">
      <div class="form-item">
        <span class="label">周期：</span>
        <el-select
          class="jn-input-style"
          v-model="formData.order_cycle"
          clearable
          placeholder="选择周期"
        >
          <el-option
            v-for="item of orderCycleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item" v-if="formData.order_cycle">
        <el-date-picker
          v-if="formData.order_cycle === '1'"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="right"
          v-model="dateTime"
          @change="(val) => changeCreateDate(val, 'daterange')"
        >
        </el-date-picker>
        <el-date-picker
          v-if="formData.order_cycle === '2'"
          v-model="weekTime"
          @change="(val) => changeCreateDate(val, 'week')"
          type="week"
          format="yyyy 第 WW 周"
          placeholder="选择周"
        >
        </el-date-picker>
        <el-date-picker
          v-if="formData.order_cycle === '3'"
          v-model="monthTime"
          @change="(val) => changeCreateDate(val, 'month')"
          type="month"
          placeholder="选择月"
        >
        </el-date-picker>
      </div>

      <div class="form-item">
        <el-select
          class="jn-input-style"
          v-model="formData.business_id"
          clearable
          placeholder="选择集配中心"
        >
          <el-option
            v-for="item of businessList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          class="jn-input-style multiple"
          placeholder="选择城市仓"
          v-model="formData.logistics_id"
          @change="onChangeCityStore"
          :filter-method="(val) => onFilterMethod('cityStore', val)"
          @visible-change="(val) => onChangeVisible('cityStore', val)"
          collapse-tags
          multiple
          filterable
          clearable
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          class="jn-input-style multiple"
          placeholder="选择自提点"
          v-model="formData.delivery_id"
          :filter-method="(val) => onFilterMethod('selfTake', val)"
          @visible-change="(val) => onChangeVisible('selfTake', val)"
          filterable
          clearable
        >
          <el-option
            v-for="item in selfTakeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <span class="label">供货周期：</span>
        <el-select
          class="jn-input-style"
          v-model="formData.delivery_cycle"
          clearable
          placeholder="选择供货周期"
        >
          <el-option
            v-for="item of deliveryCycleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
    </section>
    <!-- 表单搜索 end -->

    <!-- 内容区 start -->
    <section class="content">
      <div class="title">数据统计维度：{{ countTitle }}</div>
      <div class="count" v-loading="loadingData">
        <div class="count-wrap">
          <div v-for="item of orderDataList" :key="item.key" class="count-item">
            <div class="label">{{ item.label }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </div>
      </div>
      <div class="charts" v-loading="loadingData">
        <el-tabs v-model="activeKey" @tab-click="onHandleClick">
          <el-tab-pane
            v-for="item of orderDataList"
            :key="item.key"
            :name="item.key"
            :label="item.chartTitle"
          >
            <e-charts
              v-if="activeKey === item.key && Object.values(chartsData).length"
              :chartsData="chartsData"
            ></e-charts>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <!-- 内容区 end -->
  </section>
</template>

<script>
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import {
  postOrderDataView,
  postOrderDataViewExport,
} from "@/api/statistic/index";
import { formatDate } from "@/utils//tools/date.js";
import ECharts from "./modules/charts";

export default {
  name: "StatisticOrderCount",
  components: {
    ECharts,
  },
  data() {
    return {
      loadingData: false,
      disabledExport: false,
      dateTime: [], // 日期对象
      weekTime: "",
      monthTime: "",
      businessList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      countTitle: "上日13点至本日13点",
      formData: {
        order_cycle: "1",
        delivery_cycle: "1",
        start_time: "", // 开始时间
        end_time: "", // 结束时间
        business_id: "", // 集配中心
        logistics_id: "", // 城市仓
        delivery_id: "",
      },
      formDataClone: {}, // 复制一份，用于初始化
      orderCycleList: [
        {
          id: "1",
          name: "日",
        },
        {
          id: "2",
          name: "周",
        },
        {
          id: "3",
          name: "月",
        },
      ],
      deliveryCycleList: [
        {
          id: "1",
          name: "供货日",
        },
        {
          id: "2",
          name: "自然日",
        },
      ],
      orderDataList: [
        {
          key: "tonnage_num",
          value: "",
          label: "订单总吨数",
          chartTitle: "下单量（吨）",
          xAxisData: [],
          seriesData: [],
          suffix: "（吨）",
        },
        {
          key: "total_amount",
          value: "",
          label: "订单总金额（万元）",
          chartTitle: "下单金额（万元）",
          xAxisData: [],
          seriesData: [],
          suffix: "（万元）",
        },
        {
          key: "number",
          value: "",
          label: "订单总件数",
          chartTitle: "下单件数",
          xAxisData: [],
          seriesData: [],
          suffix: "（件）",
        },
        {
          key: "user_count",
          value: "",
          label: "总下单人数",
          chartTitle: "下单人数",
          xAxisData: [],
          seriesData: [],
          suffix: "（人）",
        },
        {
          key: "avg_amount",
          value: "",
          label: "人均下单金额",
          chartTitle: "人均下单金额",
          xAxisData: [],
          seriesData: [],
        },
        {
          key: "avg_order",
          value: "",
          label: "人均下单件数",
          chartTitle: "人均下单件数",
          xAxisData: [],
          seriesData: [],
          suffix: "（件）",
        },
        {
          key: "product_amt",
          value: "",
          label: "商品总金额（万元）",
          chartTitle: "商品总金额（万元）",
          xAxisData: [],
          seriesData: [],
          suffix: "（万元）",
        },
        {
          key: "freight_amt",
          value: "",
          label: "运费总金额（万元）",
          chartTitle: "运费总金额（万元）",
          xAxisData: [],
          seriesData: [],
          suffix: "（万元）",
        },
        {
          key: "purchase_amt",
          value: "",
          label: "服务费总金额（万元）",
          chartTitle: "服务费总金额（万元）",
          xAxisData: [],
          seriesData: [],
          suffix: "（万元）",
        },
      ],
      orderDataListClone: [],
      activeKey: "tonnage_num",
      chartsData: {},
    };
  },
  watch: {
    "formData.order_cycle"() {
      this.dateTime = [];
      this.weekTime = "";
      this.monthTime = "";
      this.onResetTime();
      this.dateTime = [this.formData.start_time, this.formData.end_time];
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.onResetTime();
      this.dateTime = [this.formData.start_time, this.formData.end_time];
      this.formDataClone = cloneDeep(this.formData);
      this.orderDataListClone = cloneDeep(this.orderDataList);
      this.chartsData = {};
      this.ajaxGetOrderDataView();
      this.ajaxGetBusinessList();
      this.ajaxGetCityStoreList();
    },
    /**
     * 获得集配中心列表
     */
    async ajaxGetBusinessList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.businessList = data;
        console.log("ajax ajaxGetBusinessList", data);
      } catch (err) {
        console.log("ajax ajaxGetBusinessList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    }, 300),
    /**
     * 获取自提点列表
     */
    ajaxGetSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      if (!logisticsId || !this.formData.logistics_id) {
        return;
      }
      const params = {
        name,
        logistics_id: logisticsId || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.deliveryList(params);
        this.selfTakeList = data.data;
        console.log("ajx ajaxGetSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetSelfTakeList err", err);
      }
    },
    500),
    /**
     * 查询列表数据
     */
    async ajaxGetOrderDataView() {
      this.loadingData = true;
      this.orderDataList = cloneDeep(this.orderDataListClone);
      const params = { ...this.formData };
      try {
        const { data } = await postOrderDataView(params);
        const { chartData, orderData } = data;
        Object.keys(orderData).forEach((_KEY) => {
          const index = this.orderDataList.findIndex(
            (item) => item.key === _KEY
          );
          this.orderDataList[index].value = orderData[_KEY];
        });
        chartData.map((item) => {
          Object.keys(item).forEach((_KEY) => {
            const index = this.orderDataList.findIndex(
              (item) => item.key === _KEY
            );
            this.orderDataList[index]?.xAxisData.push(item.date);
            this.orderDataList[index]?.seriesData.push(item[_KEY]);
          });
        });
        this.onHandleClick();
        // console.log("🆒 ajaxGetOrderDataView", this.orderDataList, chartData);
      } catch (err) {
        console.log("ajax ajaxGetOrderDataView err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * tab切换
     */
    onHandleClick() {
      this.chartsData = this.orderDataList.find(
        (item) => item.key === this.activeKey
      );
      // console.log("🆒 onHandleClick", this.chartsData);
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.ajaxGetOrderDataView();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.onResetTime();
      this.dateTime = [this.formData.start_time, this.formData.end_time];
      this.ajaxGetOrderDataView();
    },
    /**
     * 重置时间
     */
    onResetTime() {
      const day = 24 * 60 * 60 * 1000;
      const start = new Date().getTime() - day;
      this.formData.start_time = formatDate(start);
      this.formData.end_time = formatDate();
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const params = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete params.page;
        delete params.pageSize;
        await postOrderDataViewExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postOrderDataViewExport err", err);
      } finally {
        // this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // 重置自提点
      this.selfTakeList = [];
      this.formData.delivery_id = "";
      const params = {
        name: "",
        logisticsId: data,
      };
      this.ajaxGetSelfTakeList(params);
    },
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.ajaxGetCityStoreList(data);
      }
      if (type === "selfTake") {
        const params = {
          name: data,
          logisticsId: this.formData.logistics_id,
        };
        this.ajaxGetSelfTakeList(params);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.ajaxGetCityStoreList();
      }
      if (!data && type === "selfTake") {
        const params = {
          name: "",
          logisticsId: this.formData.logistics_id,
        };
        this.ajaxGetSelfTakeList(params);
      }
    },
    /**
     * 日期发生了变化
     */
    changeCreateDate(data, type) {
      if (!data) {
        this.onResetTime();
        return;
      }
      let day = 24 * 60 * 60 * 1000;
      let start = "";
      let end = "";
      if (type === "daterange") {
        start = data?.[0] || "";
        end = data?.[1] || "";
      }
      if (type === "week") {
        let startNum = data.getTime() - day;
        let endNum = data.getTime() + day * 5;
        start = formatDate(startNum);
        end = formatDate(endNum);
      }
      if (type === "month") {
        let num = new Date(
          data.getYear() % 100,
          data.getMonth() + 1,
          0
        ).getDate();
        start = formatDate(data);
        let endNum = data.getTime() + day * (num - 1);
        end = formatDate(endNum);
      }
      // console.log("🆒 changeCreateDate", data, start, end);
      this.formData.start_time = start;
      this.formData.end_time = end;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 13px 15px;
    box-sizing: border-box;
    background: #ffffff;
    &-item {
      display: flex;
      .label {
        width: 90px;
        height: 36px;
        line-height: 36px;
        text-align: right;
      }
      .el-input {
        flex: 1;
      }
      &.btn-wrap {
        position: relative;
        top: -2.5px;
        left: 20px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 20px;
    .title {
      padding: 20px;
      margin-top: 20px;
      background: #fff;
    }
    .count {
      &-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }
      &-item {
        width: 30%;
        padding: 20px;
        background: #fff;
        .value {
          padding-top: 20px;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
    .charts {
      padding: 20px;
      background: #fff;
    }
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
