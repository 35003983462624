import { BASE } from '../index';
import axios from '../http';
const base = BASE.EXPORT_CENTER;

/**
 * 总控-统计分析-采购员
 * @param {*} params
 * @returns
 */
export function postBuyerList(params = {}) {
  return axios(
    {
      method: 'GET',
      url: `${base}/bi/buyer/getBuyer`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-供应商
 * @param {*} params
 * @returns
 */
export function postSupplierList(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/supplier/list`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-城市仓-列表
 * @param {*} params
 * @returns
 */
export function postLogisticsSellStatistics(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/logistics/sell/statistics`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-城市仓-导出
 * @param {*} params
 * @returns
 */
export function postLogisticsSellStatisticsExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/logistics/sell/export`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-采购-列表
 * @param {*} params
 * @returns
 */
export function postBuyerHotSaleSellStatistics(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/buyer/hotSale/sellStatistics`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-采购-导出
 * @param {*} params
 * @returns
 */
export function postBuyerSellExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/buyer/sell/export`,
      data: params
    }
  );
};


/**
 * 总控-统计分析-供应商-列表
 * @param {*} params
 * @returns
 */
export function postSupplierDataViewList(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/supplier/dataView/list`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-供应商-导出
 * @param {*} params
 * @returns
 */
export function postSupplierOrderExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/supplier/order/export`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-供应商-列表
 * @param {*} params
 * @returns
 */
export function postAftersaleBuyerStatistics(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/aftersale/buyer/statistics`,
      data: params
    }
  );
};


/**
 * 总控-统计分析-供应商-导出
 * @param {*} params
 * @returns
 */
export function postAftersaleBuyerExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/buyer/aftersale/export`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-售后-城市仓-列表
 * @param {*} params
 * @returns
 */
export function postAftersaleLogisticsStatistics(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/aftersale/logistics/statistics`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-售后-城市仓-导出
 * @param {*} params
 * @returns
 */
export function postAftersaleLogisticsStatisticsExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/logistics/aftersale/export`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-售后-供应商-列表
 * @param {*} params
 * @returns
 */
export function postAftersaleSupplierList(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/aftersale/supplier/list`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-售后-供应商-导出
 * @param {*} params
 * @returns
 */
export function postAftersaleSupplierListExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/supplier/aftersale/export`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-售后-品类-列表
 * @param {*} params
 * @returns
 */
export function postAftersaleCategoryList(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/aftersale/category/list`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-售后-品类-导出
 * @param {*} params
 * @returns
 */
export function postAftersaleCategoryListExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/category/aftersale/export`,
      data: params
    }
  );
};


/**
 * 总控-统计分析-订单统计-概览
 * @param {*} params
 * @returns
 */
export function postOrderDataView(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/order/dataView`,
      data: params
    }
  );
};

/**
 * 总控-统计分析-订单统计-导出
 * @param {*} params
 * @returns
 */
export function postOrderDataViewExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${base}/bi/order/list/export`,
      data: params
    }
  );
};
