<template>
  <section class="charts-wrap"></section>
</template>

<script>
import * as echarts from "echarts";
import debounce from "lodash/debounce";
import { addListener, removeListener } from "resize-detector";

export default {
  name: "Charts",
  props: {
    chartsData: {
      type: Object,
      default: () => {},
    },
    /**
     * 启用自动自适应
     */
    autoResize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chart: null, // ECharts实例
      color: "#13ae67",
      dataZoomOfNum: 9,
      maxData: 25,
    };
  },
  computed: {
    /**
     * 配置项
     */
    chartsOptions() {
      const { chartTitle, xAxisData, seriesData, suffix } = this.chartsData;
      return {
        title: {
          text: chartTitle,
          top: 5,
          left: "center",
          textStyle: {
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: 14,
            fontWeight: 500,
          },
        },
        // 悬浮框
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            // console.log("🆒 formatter", params);
            const { name, value, marker } = params?.[0] || params;
            const span = `<span style="font-weight: 600;">${value}</span>`;
            const res = name && name + "<br/>" + marker + chartTitle + span;
            return res;
          },
        },
        grid: {
          left: 5,
          right: 5,
          bottom: 30,
          top: 60,
          containLabel: true,
        },
        xAxis: {
          data: xAxisData,
          axisTick: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          axisLine: {
            lineStyle: {
              color: "#e9e9e9",
            },
          },
          axisLabel: {
            color: "#888888",
            fontWeight: 400,
            // interval: 0,
            formatter: function (value) {
              return value;
            },
            margin: 15,
          },
        },
        yAxis: {
          name: suffix,
          type: "value",
          minInterval: 1,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#EAEDF4",
            },
          },
          axisPointer: {
            show: true,
          },
        },
        dataZoom: [
          {
            show: xAxisData.length > this.dataZoomOfNum,
            height: 20,
            xAxisIndex: [0],
            bottom: 5,
            start: 0,
            end: xAxisData.length > this.dataZoomOfNum ? 60 : 100,
          },
        ],
        color: this.color,
        series: {
          data: seriesData,
          type: "line",
          label: {
            show: seriesData.length <= this.maxData,
            position: "top",
            distance: 5,
            color: this.color,
            fontSize: 12,
            fontWeight: 500,
          },
        },
      };
    },
  },
  watch: {
    chartsOptions: "initOptions",
  },
  mounted() {
    // 初始化图表
    this.init();
    window.addEventListener("resize", this.onResizeECharts);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResizeECharts);
  },
  methods: {
    /**
     * 初始化图表
     */
    init() {
      // 初始化过无需重新初始化
      if (this.chart) return;
      // 创建echarts实例
      const chart = echarts.init(this.$el);
      chart.setOption(this.chartsOptions || {}, true);
      this.chart = chart;
      this.initResizeEvent();
    },
    /**
     * 图表自适应宽高
     */
    onResizeECharts: debounce(function () {
      this.autoResize && this.chart.resize();
    }, 400),
    /**
     * 初始化窗口变化事件
     */
    initResizeEvent() {
      const self = this;
      // 窗口变化重新渲染
      const __resizeHandler = debounce(() => {
        self.autoResize && self.chart.resize();
      }, 400);
      addListener(this.$el, __resizeHandler);
      this.$on("hook:destroyed", () => {
        removeListener(this.$el, __resizeHandler);
      });
    },
    /**
     * 初始化配置项
     */
    initOptions() {
      this.chart.setOption(this.chartsOptions || {});
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-wrap {
  height: 500px;
}
</style>
